import { j as s } from "./jsx-runtime-DTODbLe6.js";
import n from "styled-components";
import { A as m } from "./Avatar-DsW-FpED.js";
import "./components/Avatar/styles.es.js";
import "./Typography-pI_PskOT.js";
import { TypographyCSS as t } from "./components/Typography/styles.es.js";
import d from "clsx";
const g = n.div`
  display: flex;
  align-items: center;
  
  text-align: left;
  
  > div.texts {
    min-width: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1;
  }
  
  > div.texts span.text, > div.texts span.supportingText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  &.size-sm {
    gap: 10px;

    > div.texts span.text {
      ${t.text.sm}
      ${t.bold}
    }

    > div.texts span.supportingText {
      ${t.text.xs}
      ${t.regular}
    }
  }
  
  &.size-md {
    gap: 12px;

    > div.texts span.text {
      ${t.text.sm}
      ${t.bold}
    }

    > div.texts span.supportingText {
      ${t.text.sm}
      ${t.regular}
    }
  }

  &.size-lg {
    gap: 12px;

    > div.texts span.text {
      ${t.text.md}
      ${t.bold}
    }

    > div.texts span.supportingText {
      ${t.text.md}
      ${t.regular}
    }
  }

  &.size-xl {
    gap: 16px;

    > div.texts span.text {
      ${t.text.lg}
      ${t.bold}
    }

    > div.texts span.supportingText {
      ${t.text.md}
      ${t.regular}
    }
  }
`, c = {
  sm: "sm",
  md: "md",
  lg: "lg",
  xl: "xl"
}, j = (x) => {
  const {
    size: e = "sm",
    avatar: a,
    text: i,
    supportingText: p,
    className: r,
    ...l
  } = x, o = d(`size-${e}`, r);
  return /* @__PURE__ */ s.jsxs(g, { ...l, className: o, children: [
    /* @__PURE__ */ s.jsx(m, { size: c[e], ...a }),
    /* @__PURE__ */ s.jsxs("div", { className: "texts", children: [
      /* @__PURE__ */ s.jsx("span", { className: "text", children: i }),
      /* @__PURE__ */ s.jsx("span", { className: "supportingText", children: p })
    ] })
  ] });
};
export {
  j as A
};

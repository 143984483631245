import { j as i } from "./jsx-runtime-DTODbLe6.js";
import { useTheme as u } from "styled-components";
const x = {
  sm: (e) => e ? 6 : 4,
  md: (e) => e ? 8 : 5,
  lg: (e) => e ? 10 : 6
}, n = {
  primary: (e) => e.palette.primary[500].hex,
  gray: (e) => e.palette.grays[500].hex,
  success: (e) => e.palette.success[500].hex,
  warning: (e) => e.palette.warning[500].hex,
  error: (e) => e.palette.error[500].hex
}, a = {
  primary: (e) => e.palette.primary[100].hex,
  gray: (e) => e.palette.grays[100].hex,
  success: (e) => e.palette.success[100].hex,
  warning: (e) => e.palette.warning[100].hex,
  error: (e) => e.palette.error[100].hex
}, y = (e) => {
  const o = u(), s = e.color === "custom" ? e.fill : e.outlined ? n[e.color ?? "primary"](o) : !e.color || e.color === "inherit" ? "currentColor" : n[e.color](o), h = e.color === "custom" ? e.stroke : e.outlined ? a[e.color ?? "primary"](o) : !e.color || e.color === "inherit" ? void 0 : a[e.color](o), t = x[e.size ?? "sm"](e.outlined), r = 2 * t, l = 2 * t, c = e.outlined ? t / 2 : 0, m = e.outlined ? t - c / 2 : t - 1;
  return /* @__PURE__ */ i.jsx("svg", { width: r, height: l, viewBox: `0 0 ${r} ${l}`, fill: "none", children: /* @__PURE__ */ i.jsx("circle", { cx: t, cy: t, r: m, fill: s, stroke: h, strokeWidth: c }) });
};
export {
  y as D
};

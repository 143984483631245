import { j as a } from "./jsx-runtime-DTODbLe6.js";
import i from "styled-components";
import { A as m } from "./AvatarLabelGroup-BUkXmpkK.js";
import l from "clsx";
const d = i.button`
  margin: 0;
  
  width: 100%;
  
  text-decoration: none;
  outline: unset;
  
  border-radius: 8px;
  border: unset;
  
  cursor: pointer;
  
  > div > div.texts {
    align-items: flex-start;
    
    span {
      max-width: 100%;
    }
  }
  
  &.button-theme-default {
    background: transparent;
    
    .texts {
      
      .text {
        color: ${(e) => e.theme.palette.grays[700].hex}
      }
      
      .supportingText {
        color: ${(e) => e.theme.palette.grays[600].hex}
      }
    }
    
    
    &:hover {
      background: ${(e) => e.theme.palette.grays[100].hex}
    }
    
    &:focus {
      background: ${(e) => e.theme.palette.grays[50].hex}
      box-shadow: 0 0 0 4px ${(e) => e.theme.palette.grays[100].hex};
    }
  }
  
  &.button-theme-gray-dark {
    background: ${(e) => e.theme.palette.grays[950].hex};
    
    .texts {
      
      .text {
        color: ${(e) => e.theme.palette.grays[100].hex}
        
      }

      .supportingText {
        color: ${(e) => e.theme.palette.grays[300].hex}
      }
    }
    

    &:hover {
      background: ${(e) => e.theme.palette.grays[800].hex};
    }

    &:focus {
      background: ${(e) => e.theme.palette.grays[800].hex};
      box-shadow: 0 0 0 4px ${(e) => e.theme.palette.grays[500].hex};
    }
  }
  
  &.size-sm {
    padding: 6px;
  }  
  
  &.size-md {
    padding: 8px;
  }  
  
  &.size-lg {
    padding: 10px;
  }  
  
  &.size-xl {
    padding: 12px;
  }
`, y = (e) => {
  const {
    className: r,
    variant: s = "default",
    size: t = "sm",
    supportingText: o,
    text: n,
    avatar: x,
    onClick: p
  } = e, h = l(
    `button-theme-${s}`,
    `size-${t}`,
    r
  );
  return /* @__PURE__ */ a.jsx(d, { className: h, onClick: p, children: /* @__PURE__ */ a.jsx(
    m,
    {
      size: t,
      avatar: x,
      text: n,
      supportingText: o
    }
  ) });
};
export {
  y as A
};

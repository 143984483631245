import { j as p } from "./jsx-runtime-DTODbLe6.js";
import d from "styled-components";
import $ from "clsx";
import { TypographyCSS as s } from "./components/Typography/styles.es.js";
const g = d.p`
  font-family: Satoshi, sans-serif;

  &.variant-text {
    &.size-xs {
      ${s.text.xs}
    }

    &.size-sm {
      ${s.text.sm}
    }

    &.size-md {
      ${s.text.md}
    }

    &.size-lg {
      ${s.text.lg}
    }

    &.size-xl, &.size-xxl {
      ${s.text.xl}
    }
  }

  &.variant-display {
    &.size-xs {
      ${s.display.xs}
    }

    &.size-sm {
      ${s.display.sm}
    }

    &.size-md {
      ${s.display.md}
    }

    &.size-lg {
      ${s.display.lg}
    }

    &.size-xl {
      ${s.display.xl}
    }

    &.size-xxl {
      ${s.display.xxl}
    }
  }
  
  &.weight-regular {
    ${s.regular}
  }

  &.weight-medium {
    ${s.medium}
  }

  &.weight-bold {
    ${s.bold}
  }

  &.weight-black {
    ${s.black}
  }
`, h = (i) => {
  const {
    as: e = "p",
    variant: t = "display",
    weight: a = "regular",
    size: l = "sm",
    className: r = "",
    children: m,
    ...x
  } = i, o = $(
    `variant-${t}`,
    `weight-${a}`,
    `size-${l}`,
    r
  );
  return /* @__PURE__ */ p.jsx(g, { ...x, className: o, as: e, children: m });
};
export {
  h as T
};

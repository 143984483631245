import { j as t } from "../../jsx-runtime-DTODbLe6.js";
import o from "styled-components";
import { I as k } from "../../Icon-EVZvawy6.js";
import { T as r } from "../../Typography-pI_PskOT.js";
import "../Typography/styles.es.js";
import F from "clsx";
import { D as i } from "../../theme-BGxopWfj.js";
import { F as d } from "../../FeaturedIcon-YuslJMCX.js";
import { u as C } from "../../useDeviceQuery-BNUlEe-Z.js";
import { A as I } from "../../Avatar-DsW-FpED.js";
import "../Avatar/styles.es.js";
import { P as z } from "../../ProgressCircle-BQ6YHXFG.js";
import { i as A } from "../../pages-DOIYcuxs.js";
const T = o.div`
  width: 100%;
  max-width: 400px;

  display: inline-flex;
  gap: 16px;
  
  background: white;

  box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.03); 
  
  border-radius: 12px; 
  border: 1px ${(e) => e.theme.palette.grays[200].hex} solid;
  
  &.notification-type-image {
    .container-with-image main {
      padding-left: 0;
    }
  }
  
  @media ${i.mobile} {
    flex-direction: column;
    
    padding: 16px;
    
    .container-with-image main {
      flex-direction: column;
      
      padding: 0;
    }
  }
  
    
  &.icon-type-default {
    .notification-icon {
      border-color: ${(e) => e.theme.palette.grays[300].hex};
      background: ${(e) => e.theme.palette.white.hex};
      color: ${(e) => e.theme.palette.grays[700].hex};
    }
  }

  &.icon-type-primary {
    .notification-icon {
      border-color: ${(e) => e.theme.palette.primary[300].hex};
      background: ${(e) => e.theme.palette.primary[25].hex};
      color: ${(e) => e.theme.palette.primary[700].hex};
    }
  }

  &.icon-type-grey {
    .notification-icon {
      border-color: ${(e) => e.theme.palette.grays[300].hex};
      background: ${(e) => e.theme.palette.grays[25].hex};
      color: ${(e) => e.theme.palette.grays[700].hex};
    }
  }

  &.icon-type-success {
    .notification-icon {
      border-color: ${(e) => e.theme.palette.success[300].hex};
      background: ${(e) => e.theme.palette.success[25].hex};
      color: ${(e) => e.theme.palette.success[700].hex};
    }
  }
  
  &.icon-type-warning {
    .notification-icon {
      border-color: ${(e) => e.theme.palette.warning[300].hex};
      background: ${(e) => e.theme.palette.warning[25].hex};
      color: ${(e) => e.theme.palette.warning[700].hex};
    }
  }
  
  &.icon-type-error {
    .notification-icon {
      border-color: ${(e) => e.theme.palette.error[300].hex};
      background: ${(e) => e.theme.palette.error[25].hex};
      color: ${(e) => e.theme.palette.error[700].hex};
    }
  }
  
  .container-with-image {
    display: flex;
    width: 100%;
  }
  
  @media ${i.mobile} {
    .container-with-image {
      flex-direction: column-reverse;
      gap: 16px;
    }
  }
  
  .notification-actions-container {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: stretch;


    gap: 12px;

    > * {
      flex: 1
    }
  }
`, D = o.main`
  width: 100%;
  
  display: flex;
  
  position: relative;
  padding: 16px;
  
  
  gap: 16px;

  @media ${i.mobile} {
    gap: 8px;
  }

  button.notification-close,
  button.notification-mobile-close {
    margin-left: auto;
    padding: 0;
    
    border: none;
    background: none;

    display: flex;
    justify-content: center;
    align-items: start;

    color: ${(e) => e.theme.palette.grays[500].hex};

    cursor: pointer;
  }
  
  button.notification-mobile-close {
    position: absolute;
    top: 0;
    right: 0;
  }
  
  .notification-main-container {
    max-width: 276px;
    
    display: flex;
    align-items: start;
    flex-direction: column;
    gap: 10px;

    @media ${i.mobile} {
      gap: 4px;
    }

    overflow: hidden;
    
    > div {
      > span {
        margin-right: 8px;
        
      }
    }
    
    > footer {
      margin-top: 8px;
    }
  }
  
  .notification-progress-circle {
    display: flex;
    align-self: start;
  }
`, P = o.div`
  width: 100%;
  
  flex-direction: column;
  align-items: center;
  display: inline-flex;
  justify-content: center; 
  
  background: #F9FAFB;
  
`, x = {
  default: "info-circle",
  primary: "info-circle",
  grey: "info-circle",
  success: "check-circle",
  warning: "alert-circle",
  error: "alert-circle"
}, O = (e) => {
  const {
    text: g,
    subText: c,
    supportingText: l,
    variant: n = "no-icon",
    actions: s,
    className: h,
    onClose: u,
    ...f
  } = e, a = C(), y = F(
    e.variant === "icon" ? `icon-type-${e == null ? void 0 : e.color}` : "",
    `notification-type-${n}`,
    h
  ), b = () => e.variant === "image" ? /* @__PURE__ */ t.jsx(P, { children: /* @__PURE__ */ t.jsx(
    "img",
    {
      style: { width: "80px", height: "126px", position: "relative" },
      src: e.variant === "image" ? e == null ? void 0 : e.src : "",
      alt: e.variant === "image" ? e == null ? void 0 : e.alt : ""
    }
  ) }) : null, v = () => e.variant === "avatar" ? /* @__PURE__ */ t.jsx(I, { variant: "image", size: "sm", src: e.src ? e.src : "", alt: e == null ? void 0 : e.alt }) : null, p = () => e.variant === "icon" ? /* @__PURE__ */ t.jsx(
    d,
    {
      iconVariant: e != null && e.color ? x[e.color] : x.default,
      className: "notification-icon"
    }
  ) : null, w = () => /* @__PURE__ */ t.jsx(d, { iconVariant: "upload-cloud", className: "notification-icon" }), $ = () => e.variant === "loading" ? /* @__PURE__ */ t.jsx(z, { progress: e == null ? void 0 : e.progress, size: "xxs", className: "notification-progress-circle" }) : null, j = () => {
    switch (n) {
      case "icon":
        return p();
      case "avatar":
        return v();
      case "progress":
        return w();
      case "loading":
        return $();
      case "image":
        return /* @__PURE__ */ t.jsx(t.Fragment, {});
      case "no-icon":
        return /* @__PURE__ */ t.jsx(t.Fragment, {});
      default:
        A(n);
    }
  }, m = () => s ? /* @__PURE__ */ t.jsx("div", { className: "notification-actions-container", children: s }) : /* @__PURE__ */ t.jsx(t.Fragment, {}), N = () => /* @__PURE__ */ t.jsxs(D, { children: [
    j(),
    /* @__PURE__ */ t.jsxs("div", { className: "notification-main-container", children: [
      /* @__PURE__ */ t.jsxs("div", { children: [
        /* @__PURE__ */ t.jsx(r, { variant: "text", as: "span", size: "md", weight: "bold", children: g }),
        c && /* @__PURE__ */ t.jsx(r, { variant: "text", as: "span", size: "md", weight: "regular", children: c })
      ] }),
      l && /* @__PURE__ */ t.jsx(r, { variant: "text", as: "div", size: "md", weight: "regular", children: l }),
      a !== "mobile" && m()
    ] }),
    /* @__PURE__ */ t.jsx(
      "button",
      {
        className: a !== "mobile" ? "notification-close" : "notification-mobile-close",
        onClick: u,
        type: "button",
        children: /* @__PURE__ */ t.jsx(k, { variant: "close" })
      }
    )
  ] });
  return /* @__PURE__ */ t.jsxs(T, { ...f, className: y, children: [
    /* @__PURE__ */ t.jsxs("div", { className: "container-with-image", children: [
      b(),
      N()
    ] }),
    a === "mobile" && m()
  ] });
};
export {
  O as Notification,
  O as default
};

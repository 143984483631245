import { j as e } from "./jsx-runtime-DTODbLe6.js";
import { forwardRef as y } from "react";
import { F as A } from "./FieldLayout-DJHQGI-4.js";
import { I as S } from "./Input-C4Ruw9GX.js";
import { F as T } from "./FieldSupportingText-C7MJll6e.js";
import { F as v } from "./FieldLabel-CVzG_80p.js";
const w = y((i, l) => {
  const {
    id: r,
    as: n = "input",
    type: d = "text",
    value: p,
    error: t = !1,
    disabled: o = !1,
    required: m = !1,
    label: a,
    placeholder: u,
    supportingText: s,
    withStartAdornmentSeparator: x,
    withEndAdornmentSeparator: f,
    startAdornment: F,
    endAdornment: c,
    Layout: b = A,
    ...h
  } = i, j = a ? /* @__PURE__ */ e.jsx(
    v,
    {
      id: r,
      label: a,
      required: m
    }
  ) : void 0, I = /* @__PURE__ */ e.jsx(
    S,
    {
      id: r,
      as: n,
      ref: l,
      type: d,
      error: t,
      placeholder: u,
      disabled: o,
      value: p,
      withStartAdornmentSeparator: x,
      withEndAdornmentSeparator: f,
      startAdornment: F,
      endAdornment: c,
      ...h
    }
  ), g = s ? /* @__PURE__ */ e.jsx(
    T,
    {
      supportingText: s,
      error: t
    }
  ) : void 0;
  return /* @__PURE__ */ e.jsx(
    b,
    {
      className: `${t ? "error" : ""} ${o ? "disabled" : ""}`,
      label: j,
      element: I,
      supportingText: g
    }
  );
});
w.displayName = "InputField";
export {
  w as I
};

import { j as e } from "../../jsx-runtime-DTODbLe6.js";
import n from "styled-components";
import { NavLink as d } from "react-router-dom";
import { T as p } from "../../Typography-pI_PskOT.js";
import "../Typography/styles.es.js";
import { I as l } from "../../Icon-EVZvawy6.js";
import { B as x } from "../../Badge-BfAZypde.js";
import "../Badge/styles.es.js";
import { D as m } from "../../Dot-B_DTV58M.js";
const c = n(d)`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  gap: 8px;

  padding: 8px 12px;
  cursor: pointer;
  
  border: none;
  border-radius: 6px;
  
  text-decoration: none;
  
  // &:focus {
  //   outline: 4px solid ${(o) => o.theme.palette.grays[500].hex};
  // }
  
  
  & > div:first-child {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1 0 0;
    gap: 12px;

    min-width: 0;
    
    span {
      flex: 1 0 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    };
  }
`, I = (o) => {
  const {
    badge: t,
    text: r,
    icon: i,
    dot: s,
    ...a
  } = o;
  return /* @__PURE__ */ e.jsxs(c, { ...a, children: [
    /* @__PURE__ */ e.jsxs("div", { children: [
      s ? /* @__PURE__ */ e.jsx(m, { size: "md" }) : void 0,
      i ? /* @__PURE__ */ e.jsx(l, { variant: i }) : void 0,
      /* @__PURE__ */ e.jsx(p, { variant: "text", size: "md", weight: "bold", as: "span", children: r })
    ] }),
    t ? /* @__PURE__ */ e.jsx(x, { color: "gray", text: t }) : void 0
  ] });
};
export {
  I as SidebarItem,
  I as default
};

import { g as O } from "./_commonjsHelpers-DaMA6jEr.js";
import { c as T } from "./_commonjs-dynamic-modules-BpilXLfW.js";
var R = { exports: {} };
/*! @preserve
 * numeral.js
 * version : 2.0.6
 * author : Adam Draper
 * license : MIT
 * http://adamwdraper.github.com/Numeral-js/
 */
var C = R.exports, S;
function j() {
  return S || (S = 1, function(z) {
    (function(a, h) {
      z.exports ? z.exports = h() : a.numeral = h();
    })(C, function() {
      var a, h, w = "2.0.6", v = {}, y = {}, d = {
        currentLocale: "en",
        zeroFormat: null,
        nullFormat: null,
        defaultFormat: "0,0",
        scalePercentBy100: !0
      }, m = {
        currentLocale: d.currentLocale,
        zeroFormat: d.zeroFormat,
        nullFormat: d.nullFormat,
        defaultFormat: d.defaultFormat,
        scalePercentBy100: d.scalePercentBy100
      };
      function F(e, r) {
        this._input = e, this._value = r;
      }
      return a = function(e) {
        var r, l, o, t;
        if (a.isNumeral(e))
          r = e.value();
        else if (e === 0 || typeof e > "u")
          r = 0;
        else if (e === null || h.isNaN(e))
          r = null;
        else if (typeof e == "string")
          if (m.zeroFormat && e === m.zeroFormat)
            r = 0;
          else if (m.nullFormat && e === m.nullFormat || !e.replace(/[^0-9]+/g, "").length)
            r = null;
          else {
            for (l in v)
              if (t = typeof v[l].regexps.unformat == "function" ? v[l].regexps.unformat() : v[l].regexps.unformat, t && e.match(t)) {
                o = v[l].unformat;
                break;
              }
            o = o || a._.stringToNumber, r = o(e);
          }
        else
          r = Number(e) || null;
        return new F(e, r);
      }, a.version = w, a.isNumeral = function(e) {
        return e instanceof F;
      }, a._ = h = {
        // formats numbers separators, decimals places, signs, abbreviations
        numberToFormat: function(e, r, l) {
          var o = y[a.options.currentLocale], t = !1, n = !1, i = 0, s = "", c = 1e12, u = 1e9, f = 1e6, b = 1e3, g = "", B = !1, $, N, p, x, P, E, _;
          if (e = e || 0, N = Math.abs(e), a._.includes(r, "(") ? (t = !0, r = r.replace(/[\(|\)]/g, "")) : (a._.includes(r, "+") || a._.includes(r, "-")) && (P = a._.includes(r, "+") ? r.indexOf("+") : e < 0 ? r.indexOf("-") : -1, r = r.replace(/[\+|\-]/g, "")), a._.includes(r, "a") && ($ = r.match(/a(k|m|b|t)?/), $ = $ ? $[1] : !1, a._.includes(r, " a") && (s = " "), r = r.replace(new RegExp(s + "a[kmbt]?"), ""), N >= c && !$ || $ === "t" ? (s += o.abbreviations.trillion, e = e / c) : N < c && N >= u && !$ || $ === "b" ? (s += o.abbreviations.billion, e = e / u) : N < u && N >= f && !$ || $ === "m" ? (s += o.abbreviations.million, e = e / f) : (N < f && N >= b && !$ || $ === "k") && (s += o.abbreviations.thousand, e = e / b)), a._.includes(r, "[.]") && (n = !0, r = r.replace("[.]", ".")), p = e.toString().split(".")[0], x = r.split(".")[1], E = r.indexOf(","), i = (r.split(".")[0].split(",")[0].match(/0/g) || []).length, x ? (a._.includes(x, "[") ? (x = x.replace("]", ""), x = x.split("["), g = a._.toFixed(e, x[0].length + x[1].length, l, x[1].length)) : g = a._.toFixed(e, x.length, l), p = g.split(".")[0], a._.includes(g, ".") ? g = o.delimiters.decimal + g.split(".")[1] : g = "", n && Number(g.slice(1)) === 0 && (g = "")) : p = a._.toFixed(e, 0, l), s && !$ && Number(p) >= 1e3 && s !== o.abbreviations.trillion)
            switch (p = String(Number(p) / 1e3), s) {
              case o.abbreviations.thousand:
                s = o.abbreviations.million;
                break;
              case o.abbreviations.million:
                s = o.abbreviations.billion;
                break;
              case o.abbreviations.billion:
                s = o.abbreviations.trillion;
                break;
            }
          if (a._.includes(p, "-") && (p = p.slice(1), B = !0), p.length < i)
            for (var M = i - p.length; M > 0; M--)
              p = "0" + p;
          return E > -1 && (p = p.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1" + o.delimiters.thousands)), r.indexOf(".") === 0 && (p = ""), _ = p + g + (s || ""), t ? _ = (t && B ? "(" : "") + _ + (t && B ? ")" : "") : P >= 0 ? _ = P === 0 ? (B ? "-" : "+") + _ : _ + (B ? "-" : "+") : B && (_ = "-" + _), _;
        },
        // unformats numbers separators, decimals places, signs, abbreviations
        stringToNumber: function(e) {
          var r = y[m.currentLocale], l = e, o = {
            thousand: 3,
            million: 6,
            billion: 9,
            trillion: 12
          }, t, n, i;
          if (m.zeroFormat && e === m.zeroFormat)
            n = 0;
          else if (m.nullFormat && e === m.nullFormat || !e.replace(/[^0-9]+/g, "").length)
            n = null;
          else {
            n = 1, r.delimiters.decimal !== "." && (e = e.replace(/\./g, "").replace(r.delimiters.decimal, "."));
            for (t in o)
              if (i = new RegExp("[^a-zA-Z]" + r.abbreviations[t] + "(?:\\)|(\\" + r.currency.symbol + ")?(?:\\))?)?$"), l.match(i)) {
                n *= Math.pow(10, o[t]);
                break;
              }
            n *= (e.split("-").length + Math.min(e.split("(").length - 1, e.split(")").length - 1)) % 2 ? 1 : -1, e = e.replace(/[^0-9\.]+/g, ""), n *= Number(e);
          }
          return n;
        },
        isNaN: function(e) {
          return typeof e == "number" && isNaN(e);
        },
        includes: function(e, r) {
          return e.indexOf(r) !== -1;
        },
        insert: function(e, r, l) {
          return e.slice(0, l) + r + e.slice(l);
        },
        reduce: function(e, r) {
          if (this === null)
            throw new TypeError("Array.prototype.reduce called on null or undefined");
          if (typeof r != "function")
            throw new TypeError(r + " is not a function");
          var l = Object(e), o = l.length >>> 0, t = 0, n;
          if (arguments.length === 3)
            n = arguments[2];
          else {
            for (; t < o && !(t in l); )
              t++;
            if (t >= o)
              throw new TypeError("Reduce of empty array with no initial value");
            n = l[t++];
          }
          for (; t < o; t++)
            t in l && (n = r(n, l[t], t, l));
          return n;
        },
        /**
         * Computes the multiplier necessary to make x >= 1,
         * effectively eliminating miscalculations caused by
         * finite precision.
         */
        multiplier: function(e) {
          var r = e.toString().split(".");
          return r.length < 2 ? 1 : Math.pow(10, r[1].length);
        },
        /**
         * Given a variable number of arguments, returns the maximum
         * multiplier that must be used to normalize an operation involving
         * all of them.
         */
        correctionFactor: function() {
          var e = Array.prototype.slice.call(arguments);
          return e.reduce(function(r, l) {
            var o = h.multiplier(l);
            return r > o ? r : o;
          }, 1);
        },
        /**
         * Implementation of toFixed() that treats floats more like decimals
         *
         * Fixes binary rounding issues (eg. (0.615).toFixed(2) === '0.61') that present
         * problems for accounting- and finance-related software.
         */
        toFixed: function(e, r, l, o) {
          var t = e.toString().split("."), n = r - (o || 0), i, s, c, u;
          return t.length === 2 ? i = Math.min(Math.max(t[1].length, n), r) : i = n, c = Math.pow(10, i), u = (l(e + "e+" + i) / c).toFixed(i), o > r - i && (s = new RegExp("\\.?0{1," + (o - (r - i)) + "}$"), u = u.replace(s, "")), u;
        }
      }, a.options = m, a.formats = v, a.locales = y, a.locale = function(e) {
        return e && (m.currentLocale = e.toLowerCase()), m.currentLocale;
      }, a.localeData = function(e) {
        if (!e)
          return y[m.currentLocale];
        if (e = e.toLowerCase(), !y[e])
          throw new Error("Unknown locale : " + e);
        return y[e];
      }, a.reset = function() {
        for (var e in d)
          m[e] = d[e];
      }, a.zeroFormat = function(e) {
        m.zeroFormat = typeof e == "string" ? e : null;
      }, a.nullFormat = function(e) {
        m.nullFormat = typeof e == "string" ? e : null;
      }, a.defaultFormat = function(e) {
        m.defaultFormat = typeof e == "string" ? e : "0.0";
      }, a.register = function(e, r, l) {
        if (r = r.toLowerCase(), this[e + "s"][r])
          throw new TypeError(r + " " + e + " already registered.");
        return this[e + "s"][r] = l, l;
      }, a.validate = function(e, r) {
        var l, o, t, n, i, s, c, u;
        if (typeof e != "string" && (e += "", console.warn && console.warn("Numeral.js: Value is not string. It has been co-erced to: ", e)), e = e.trim(), e.match(/^\d+$/))
          return !0;
        if (e === "")
          return !1;
        try {
          c = a.localeData(r);
        } catch {
          c = a.localeData(a.locale());
        }
        return t = c.currency.symbol, i = c.abbreviations, l = c.delimiters.decimal, c.delimiters.thousands === "." ? o = "\\." : o = c.delimiters.thousands, u = e.match(/^[^\d]+/), u !== null && (e = e.substr(1), u[0] !== t) || (u = e.match(/[^\d]+$/), u !== null && (e = e.slice(0, -1), u[0] !== i.thousand && u[0] !== i.million && u[0] !== i.billion && u[0] !== i.trillion)) ? !1 : (s = new RegExp(o + "{2}"), e.match(/[^\d.,]/g) ? !1 : (n = e.split(l), n.length > 2 ? !1 : n.length < 2 ? !!n[0].match(/^\d+.*\d$/) && !n[0].match(s) : n[0].length === 1 ? !!n[0].match(/^\d+$/) && !n[0].match(s) && !!n[1].match(/^\d+$/) : !!n[0].match(/^\d+.*\d$/) && !n[0].match(s) && !!n[1].match(/^\d+$/)));
      }, a.fn = F.prototype = {
        clone: function() {
          return a(this);
        },
        format: function(e, r) {
          var l = this._value, o = e || m.defaultFormat, t, n, i;
          if (r = r || Math.round, l === 0 && m.zeroFormat !== null)
            n = m.zeroFormat;
          else if (l === null && m.nullFormat !== null)
            n = m.nullFormat;
          else {
            for (t in v)
              if (o.match(v[t].regexps.format)) {
                i = v[t].format;
                break;
              }
            i = i || a._.numberToFormat, n = i(l, o, r);
          }
          return n;
        },
        value: function() {
          return this._value;
        },
        input: function() {
          return this._input;
        },
        set: function(e) {
          return this._value = Number(e), this;
        },
        add: function(e) {
          var r = h.correctionFactor.call(null, this._value, e);
          function l(o, t, n, i) {
            return o + Math.round(r * t);
          }
          return this._value = h.reduce([this._value, e], l, 0) / r, this;
        },
        subtract: function(e) {
          var r = h.correctionFactor.call(null, this._value, e);
          function l(o, t, n, i) {
            return o - Math.round(r * t);
          }
          return this._value = h.reduce([e], l, Math.round(this._value * r)) / r, this;
        },
        multiply: function(e) {
          function r(l, o, t, n) {
            var i = h.correctionFactor(l, o);
            return Math.round(l * i) * Math.round(o * i) / Math.round(i * i);
          }
          return this._value = h.reduce([this._value, e], r, 1), this;
        },
        divide: function(e) {
          function r(l, o, t, n) {
            var i = h.correctionFactor(l, o);
            return Math.round(l * i) / Math.round(o * i);
          }
          return this._value = h.reduce([this._value, e], r), this;
        },
        difference: function(e) {
          return Math.abs(a(this._value).subtract(e).value());
        }
      }, a.register("locale", "en", {
        delimiters: {
          thousands: ",",
          decimal: "."
        },
        abbreviations: {
          thousand: "k",
          million: "m",
          billion: "b",
          trillion: "t"
        },
        ordinal: function(e) {
          var r = e % 10;
          return ~~(e % 100 / 10) === 1 ? "th" : r === 1 ? "st" : r === 2 ? "nd" : r === 3 ? "rd" : "th";
        },
        currency: {
          symbol: "$"
        }
      }), function() {
        a.register("format", "bps", {
          regexps: {
            format: /(BPS)/,
            unformat: /(BPS)/
          },
          format: function(e, r, l) {
            var o = a._.includes(r, " BPS") ? " " : "", t;
            return e = e * 1e4, r = r.replace(/\s?BPS/, ""), t = a._.numberToFormat(e, r, l), a._.includes(t, ")") ? (t = t.split(""), t.splice(-1, 0, o + "BPS"), t = t.join("")) : t = t + o + "BPS", t;
          },
          unformat: function(e) {
            return +(a._.stringToNumber(e) * 1e-4).toFixed(15);
          }
        });
      }(), function() {
        var e = {
          base: 1e3,
          suffixes: ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
        }, r = {
          base: 1024,
          suffixes: ["B", "KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"]
        }, l = e.suffixes.concat(r.suffixes.filter(function(t) {
          return e.suffixes.indexOf(t) < 0;
        })), o = l.join("|");
        o = "(" + o.replace("B", "B(?!PS)") + ")", a.register("format", "bytes", {
          regexps: {
            format: /([0\s]i?b)/,
            unformat: new RegExp(o)
          },
          format: function(t, n, i) {
            var s, c = a._.includes(n, "ib") ? r : e, u = a._.includes(n, " b") || a._.includes(n, " ib") ? " " : "", f, b, g;
            for (n = n.replace(/\s?i?b/, ""), f = 0; f <= c.suffixes.length; f++)
              if (b = Math.pow(c.base, f), g = Math.pow(c.base, f + 1), t === null || t === 0 || t >= b && t < g) {
                u += c.suffixes[f], b > 0 && (t = t / b);
                break;
              }
            return s = a._.numberToFormat(t, n, i), s + u;
          },
          unformat: function(t) {
            var n = a._.stringToNumber(t), i, s;
            if (n) {
              for (i = e.suffixes.length - 1; i >= 0; i--) {
                if (a._.includes(t, e.suffixes[i])) {
                  s = Math.pow(e.base, i);
                  break;
                }
                if (a._.includes(t, r.suffixes[i])) {
                  s = Math.pow(r.base, i);
                  break;
                }
              }
              n *= s || 1;
            }
            return n;
          }
        });
      }(), function() {
        a.register("format", "currency", {
          regexps: {
            format: /(\$)/
          },
          format: function(e, r, l) {
            var o = a.locales[a.options.currentLocale], t = {
              before: r.match(/^([\+|\-|\(|\s|\$]*)/)[0],
              after: r.match(/([\+|\-|\)|\s|\$]*)$/)[0]
            }, n, i, s;
            for (r = r.replace(/\s?\$\s?/, ""), n = a._.numberToFormat(e, r, l), e >= 0 ? (t.before = t.before.replace(/[\-\(]/, ""), t.after = t.after.replace(/[\-\)]/, "")) : e < 0 && !a._.includes(t.before, "-") && !a._.includes(t.before, "(") && (t.before = "-" + t.before), s = 0; s < t.before.length; s++)
              switch (i = t.before[s], i) {
                case "$":
                  n = a._.insert(n, o.currency.symbol, s);
                  break;
                case " ":
                  n = a._.insert(n, " ", s + o.currency.symbol.length - 1);
                  break;
              }
            for (s = t.after.length - 1; s >= 0; s--)
              switch (i = t.after[s], i) {
                case "$":
                  n = s === t.after.length - 1 ? n + o.currency.symbol : a._.insert(n, o.currency.symbol, -(t.after.length - (1 + s)));
                  break;
                case " ":
                  n = s === t.after.length - 1 ? n + " " : a._.insert(n, " ", -(t.after.length - (1 + s) + o.currency.symbol.length - 1));
                  break;
              }
            return n;
          }
        });
      }(), function() {
        a.register("format", "exponential", {
          regexps: {
            format: /(e\+|e-)/,
            unformat: /(e\+|e-)/
          },
          format: function(e, r, l) {
            var o, t = typeof e == "number" && !a._.isNaN(e) ? e.toExponential() : "0e+0", n = t.split("e");
            return r = r.replace(/e[\+|\-]{1}0/, ""), o = a._.numberToFormat(Number(n[0]), r, l), o + "e" + n[1];
          },
          unformat: function(e) {
            var r = a._.includes(e, "e+") ? e.split("e+") : e.split("e-"), l = Number(r[0]), o = Number(r[1]);
            o = a._.includes(e, "e-") ? o *= -1 : o;
            function t(n, i, s, c) {
              var u = a._.correctionFactor(n, i), f = n * u * (i * u) / (u * u);
              return f;
            }
            return a._.reduce([l, Math.pow(10, o)], t, 1);
          }
        });
      }(), function() {
        a.register("format", "ordinal", {
          regexps: {
            format: /(o)/
          },
          format: function(e, r, l) {
            var o = a.locales[a.options.currentLocale], t, n = a._.includes(r, " o") ? " " : "";
            return r = r.replace(/\s?o/, ""), n += o.ordinal(e), t = a._.numberToFormat(e, r, l), t + n;
          }
        });
      }(), function() {
        a.register("format", "percentage", {
          regexps: {
            format: /(%)/,
            unformat: /(%)/
          },
          format: function(e, r, l) {
            var o = a._.includes(r, " %") ? " " : "", t;
            return a.options.scalePercentBy100 && (e = e * 100), r = r.replace(/\s?\%/, ""), t = a._.numberToFormat(e, r, l), a._.includes(t, ")") ? (t = t.split(""), t.splice(-1, 0, o + "%"), t = t.join("")) : t = t + o + "%", t;
          },
          unformat: function(e) {
            var r = a._.stringToNumber(e);
            return a.options.scalePercentBy100 ? r * 0.01 : r;
          }
        });
      }(), function() {
        a.register("format", "time", {
          regexps: {
            format: /(:)/,
            unformat: /(:)/
          },
          format: function(e, r, l) {
            var o = Math.floor(e / 60 / 60), t = Math.floor((e - o * 60 * 60) / 60), n = Math.round(e - o * 60 * 60 - t * 60);
            return o + ":" + (t < 10 ? "0" + t : t) + ":" + (n < 10 ? "0" + n : n);
          },
          unformat: function(e) {
            var r = e.split(":"), l = 0;
            return r.length === 3 ? (l = l + Number(r[0]) * 60 * 60, l = l + Number(r[1]) * 60, l = l + Number(r[2])) : r.length === 2 && (l = l + Number(r[0]) * 60, l = l + Number(r[1])), Number(l);
          }
        });
      }(), a;
    });
  }(R)), R.exports;
}
var q = j();
const W = /* @__PURE__ */ O(q);
var k = { exports: {} }, I = k.exports, L;
function U() {
  return L || (L = 1, function(z, a) {
    (function(h, w) {
      typeof T == "function" ? z.exports = w() : h.pluralize = w();
    })(I, function() {
      var h = [], w = [], v = {}, y = {}, d = {};
      function m(i) {
        return typeof i == "string" ? new RegExp("^" + i + "$", "i") : i;
      }
      function F(i, s) {
        return i === s ? s : i === i.toLowerCase() ? s.toLowerCase() : i === i.toUpperCase() ? s.toUpperCase() : i[0] === i[0].toUpperCase() ? s.charAt(0).toUpperCase() + s.substr(1).toLowerCase() : s.toLowerCase();
      }
      function e(i, s) {
        return i.replace(/\$(\d{1,2})/g, function(c, u) {
          return s[u] || "";
        });
      }
      function r(i, s) {
        return i.replace(s[0], function(c, u) {
          var f = e(s[1], arguments);
          return F(c === "" ? i[u - 1] : c, f);
        });
      }
      function l(i, s, c) {
        if (!i.length || v.hasOwnProperty(i))
          return s;
        for (var u = c.length; u--; ) {
          var f = c[u];
          if (f[0].test(s)) return r(s, f);
        }
        return s;
      }
      function o(i, s, c) {
        return function(u) {
          var f = u.toLowerCase();
          return s.hasOwnProperty(f) ? F(u, f) : i.hasOwnProperty(f) ? F(u, i[f]) : l(f, u, c);
        };
      }
      function t(i, s, c, u) {
        return function(f) {
          var b = f.toLowerCase();
          return s.hasOwnProperty(b) ? !0 : i.hasOwnProperty(b) ? !1 : l(b, b, c) === b;
        };
      }
      function n(i, s, c) {
        var u = s === 1 ? n.singular(i) : n.plural(i);
        return (c ? s + " " : "") + u;
      }
      return n.plural = o(
        d,
        y,
        h
      ), n.isPlural = t(
        d,
        y,
        h
      ), n.singular = o(
        y,
        d,
        w
      ), n.isSingular = t(
        y,
        d,
        w
      ), n.addPluralRule = function(i, s) {
        h.push([m(i), s]);
      }, n.addSingularRule = function(i, s) {
        w.push([m(i), s]);
      }, n.addUncountableRule = function(i) {
        if (typeof i == "string") {
          v[i.toLowerCase()] = !0;
          return;
        }
        n.addPluralRule(i, "$0"), n.addSingularRule(i, "$0");
      }, n.addIrregularRule = function(i, s) {
        s = s.toLowerCase(), i = i.toLowerCase(), d[i] = s, y[s] = i;
      }, [
        // Pronouns.
        ["I", "we"],
        ["me", "us"],
        ["he", "they"],
        ["she", "they"],
        ["them", "them"],
        ["myself", "ourselves"],
        ["yourself", "yourselves"],
        ["itself", "themselves"],
        ["herself", "themselves"],
        ["himself", "themselves"],
        ["themself", "themselves"],
        ["is", "are"],
        ["was", "were"],
        ["has", "have"],
        ["this", "these"],
        ["that", "those"],
        // Words ending in with a consonant and `o`.
        ["echo", "echoes"],
        ["dingo", "dingoes"],
        ["volcano", "volcanoes"],
        ["tornado", "tornadoes"],
        ["torpedo", "torpedoes"],
        // Ends with `us`.
        ["genus", "genera"],
        ["viscus", "viscera"],
        // Ends with `ma`.
        ["stigma", "stigmata"],
        ["stoma", "stomata"],
        ["dogma", "dogmata"],
        ["lemma", "lemmata"],
        ["schema", "schemata"],
        ["anathema", "anathemata"],
        // Other irregular rules.
        ["ox", "oxen"],
        ["axe", "axes"],
        ["die", "dice"],
        ["yes", "yeses"],
        ["foot", "feet"],
        ["eave", "eaves"],
        ["goose", "geese"],
        ["tooth", "teeth"],
        ["quiz", "quizzes"],
        ["human", "humans"],
        ["proof", "proofs"],
        ["carve", "carves"],
        ["valve", "valves"],
        ["looey", "looies"],
        ["thief", "thieves"],
        ["groove", "grooves"],
        ["pickaxe", "pickaxes"],
        ["passerby", "passersby"]
      ].forEach(function(i) {
        return n.addIrregularRule(i[0], i[1]);
      }), [
        [/s?$/i, "s"],
        [/[^\u0000-\u007F]$/i, "$0"],
        [/([^aeiou]ese)$/i, "$1"],
        [/(ax|test)is$/i, "$1es"],
        [/(alias|[^aou]us|t[lm]as|gas|ris)$/i, "$1es"],
        [/(e[mn]u)s?$/i, "$1s"],
        [/([^l]ias|[aeiou]las|[ejzr]as|[iu]am)$/i, "$1"],
        [/(alumn|syllab|vir|radi|nucle|fung|cact|stimul|termin|bacill|foc|uter|loc|strat)(?:us|i)$/i, "$1i"],
        [/(alumn|alg|vertebr)(?:a|ae)$/i, "$1ae"],
        [/(seraph|cherub)(?:im)?$/i, "$1im"],
        [/(her|at|gr)o$/i, "$1oes"],
        [/(agend|addend|millenni|dat|extrem|bacteri|desiderat|strat|candelabr|errat|ov|symposi|curricul|automat|quor)(?:a|um)$/i, "$1a"],
        [/(apheli|hyperbat|periheli|asyndet|noumen|phenomen|criteri|organ|prolegomen|hedr|automat)(?:a|on)$/i, "$1a"],
        [/sis$/i, "ses"],
        [/(?:(kni|wi|li)fe|(ar|l|ea|eo|oa|hoo)f)$/i, "$1$2ves"],
        [/([^aeiouy]|qu)y$/i, "$1ies"],
        [/([^ch][ieo][ln])ey$/i, "$1ies"],
        [/(x|ch|ss|sh|zz)$/i, "$1es"],
        [/(matr|cod|mur|sil|vert|ind|append)(?:ix|ex)$/i, "$1ices"],
        [/\b((?:tit)?m|l)(?:ice|ouse)$/i, "$1ice"],
        [/(pe)(?:rson|ople)$/i, "$1ople"],
        [/(child)(?:ren)?$/i, "$1ren"],
        [/eaux$/i, "$0"],
        [/m[ae]n$/i, "men"],
        ["thou", "you"]
      ].forEach(function(i) {
        return n.addPluralRule(i[0], i[1]);
      }), [
        [/s$/i, ""],
        [/(ss)$/i, "$1"],
        [/(wi|kni|(?:after|half|high|low|mid|non|night|[^\w]|^)li)ves$/i, "$1fe"],
        [/(ar|(?:wo|[ae])l|[eo][ao])ves$/i, "$1f"],
        [/ies$/i, "y"],
        [/\b([pl]|zomb|(?:neck|cross)?t|coll|faer|food|gen|goon|group|lass|talk|goal|cut)ies$/i, "$1ie"],
        [/\b(mon|smil)ies$/i, "$1ey"],
        [/\b((?:tit)?m|l)ice$/i, "$1ouse"],
        [/(seraph|cherub)im$/i, "$1"],
        [/(x|ch|ss|sh|zz|tto|go|cho|alias|[^aou]us|t[lm]as|gas|(?:her|at|gr)o|[aeiou]ris)(?:es)?$/i, "$1"],
        [/(analy|diagno|parenthe|progno|synop|the|empha|cri|ne)(?:sis|ses)$/i, "$1sis"],
        [/(movie|twelve|abuse|e[mn]u)s$/i, "$1"],
        [/(test)(?:is|es)$/i, "$1is"],
        [/(alumn|syllab|vir|radi|nucle|fung|cact|stimul|termin|bacill|foc|uter|loc|strat)(?:us|i)$/i, "$1us"],
        [/(agend|addend|millenni|dat|extrem|bacteri|desiderat|strat|candelabr|errat|ov|symposi|curricul|quor)a$/i, "$1um"],
        [/(apheli|hyperbat|periheli|asyndet|noumen|phenomen|criteri|organ|prolegomen|hedr|automat)a$/i, "$1on"],
        [/(alumn|alg|vertebr)ae$/i, "$1a"],
        [/(cod|mur|sil|vert|ind)ices$/i, "$1ex"],
        [/(matr|append)ices$/i, "$1ix"],
        [/(pe)(rson|ople)$/i, "$1rson"],
        [/(child)ren$/i, "$1"],
        [/(eau)x?$/i, "$1"],
        [/men$/i, "man"]
      ].forEach(function(i) {
        return n.addSingularRule(i[0], i[1]);
      }), [
        // Singular words with no plurals.
        "adulthood",
        "advice",
        "agenda",
        "aid",
        "aircraft",
        "alcohol",
        "ammo",
        "analytics",
        "anime",
        "athletics",
        "audio",
        "bison",
        "blood",
        "bream",
        "buffalo",
        "butter",
        "carp",
        "cash",
        "chassis",
        "chess",
        "clothing",
        "cod",
        "commerce",
        "cooperation",
        "corps",
        "debris",
        "diabetes",
        "digestion",
        "elk",
        "energy",
        "equipment",
        "excretion",
        "expertise",
        "firmware",
        "flounder",
        "fun",
        "gallows",
        "garbage",
        "graffiti",
        "hardware",
        "headquarters",
        "health",
        "herpes",
        "highjinks",
        "homework",
        "housework",
        "information",
        "jeans",
        "justice",
        "kudos",
        "labour",
        "literature",
        "machinery",
        "mackerel",
        "mail",
        "media",
        "mews",
        "moose",
        "music",
        "mud",
        "manga",
        "news",
        "only",
        "personnel",
        "pike",
        "plankton",
        "pliers",
        "police",
        "pollution",
        "premises",
        "rain",
        "research",
        "rice",
        "salmon",
        "scissors",
        "series",
        "sewage",
        "shambles",
        "shrimp",
        "software",
        "species",
        "staff",
        "swine",
        "tennis",
        "traffic",
        "transportation",
        "trout",
        "tuna",
        "wealth",
        "welfare",
        "whiting",
        "wildebeest",
        "wildlife",
        "you",
        /pok[eé]mon$/i,
        // Regexes.
        /[^aeiou]ese$/i,
        // "chinese", "japanese"
        /deer$/i,
        // "deer", "reindeer"
        /fish$/i,
        // "fish", "blowfish", "angelfish"
        /measles$/i,
        /o[iu]s$/i,
        // "carnivorous"
        /pox$/i,
        // "chickpox", "smallpox"
        /sheep$/i
      ].forEach(n.addUncountableRule), n;
    });
  }(k)), k.exports;
}
var A = U();
const Z = /* @__PURE__ */ O(A);
function G(z, a = !1) {
  if (a)
    throw new Error(`ERROR! The value ${JSON.stringify(z)} should be of type never.`);
  return z;
}
export {
  G as i,
  W as n,
  Z as p
};
